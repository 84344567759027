/**
 * Form validation
 */

export default (() => {
  const selector = 'contact-form'

  window.addEventListener(
    'load',
    () => {
      const forms = document.getElementsByClassName(selector)

      /* eslint-disable no-unused-vars */
      const formHandler = Array.prototype.filter.call(forms, (form) => {
        const formActionUrl = form.getAttribute('action')
        const submitBtn = form.querySelector('button[type="submit"]')
        const spinner = `<span class="spinner-border spinner-border-sm ms-n2 me-2" role="status"></span>`
        const successAlert = form.querySelector('.alert-success')
        const errorAlert = form.querySelector('.alert-danger')
        const errorAlertText = errorAlert.querySelector('.error-text')

        const formControls = form.querySelectorAll('.form-control')
        const formFields = {}

        form.addEventListener(
          'submit',
          (e) => {
            e.preventDefault()
            e.stopPropagation()

            if (form.checkValidity() === false) {
              form.classList.add('was-validated')
            } else {
              submitBtn.disabled = true
              submitBtn.innerHTML = spinner + submitBtn.innerHTML

              formControls.forEach((control) => {
                const name = control.getAttribute('name')
                const value = control.value
                formFields[name] = value
              })

              fetch(formActionUrl, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
                body: JSON.stringify(formFields),
              })
                .then((response) => {
                  form.reset()
                  submitBtn.disabled = false
                  submitBtn.innerHTML = submitBtn.innerHTML.replace(spinner, '')
                  successAlert.classList.remove('d-none')
                  setTimeout(() => {
                    successAlert.classList.add('d-none')
                  }, 4000)
                })
                .catch((error) => {
                  submitBtn.disabled = false
                  submitBtn.innerHTML = submitBtn.innerHTML.replace(spinner, '')
                  errorAlert.classList.remove('d-none')
                  errorAlertText.innertext = error
                  setTimeout(() => {
                    errorAlert.classList.add('d-none')
                    errorAlertText.innertext = ''
                  }, 4000)
                })
            }
          },
          false
        )
      })
      /* eslint-enable no-unused-vars */
    },
    false
  )
})()
